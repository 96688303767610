import React, { useEffect, useState } from "react"
import { Accordion } from "react-bootstrap"
import AxiosInstance from "../../../services/axiosInstance"
import AddSwiftPhoneModal from "./addSwiftPhoneModal"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const Phone = ({ driveList }) => {
  const [phoneOptOut, setPhoneOptOput] = useState(""),
    [showTwilioPhone, setShowTwilioPhone] = useState(false),
    [showPhoneModal, setShowPhoneModal] = useState(false),
    [phoneListData, setPhoneListData] = useState([]),
    [phoneEditId, setPhoneEditId] = useState(""),
    [twilioAccountSid, setTwilioAccountSid] = useState("ACXXXXXXXXXXXXXXXXXXXXXXXXXXXX"),
    [twilioAuthToken, setTwilioAuthToken] = useState(""),
    [phoneNumber, setPhoneNumber] = useState("+1XXXXXXXXX")

  const savePhoneOptOuts = id => {
    if (id === "") {
      return
    }

    AxiosInstance.post(`/settings/imap/save-phone-opt-outs/${id}`)
      .then(response => {
        if (response.status === 200) {
          setPhoneOptOput(id)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getPhoneOptOut = () => {
    AxiosInstance.get(`/settings/general-setting/phone-opt-out`).then(response => {
      setPhoneOptOput(response.data.data)
    })
  }

  const saveSettingPhone = e => {
    e.preventDefault()

    const formData = new FormData(e.target)
    if (!formData.get("phoneNumber")) {
      alert("Please enter Phone Number.")
      return false
    }

    if (!formData.get("twilioAccountSid")) {
      alert("Please Enter Twilio Account SID.")
      return false
    }

    if (!formData.get("twilioAuthToken")) {
      alert("Please Enter Twilio Auth Token.")
      return false
    }

    var requestData = {
      type: "TwilioPhone",
      twilioAccountSid: formData.get("twilioAccountSid"),
      twilioAuthToken: formData.get("twilioAuthToken"),
      phoneNumber: formData.get("phoneNumber"),
    }

    var url = `settings/phone/add`
    if (phoneEditId) {
      url = `settings/phone/edit/${phoneEditId}`
    }

    return new Promise(async () => {
      await AxiosInstance.post(url, requestData)
        .then(response => {
          if (response.status === 201 || response.status === 200) {
            getPhoneListData()
            setDefaultValue()
            return false
          }
        })
        .catch(function (error) {})
    })
  }

  const getPhoneListData = async () => {
    AxiosInstance.get("/settings/phone").then(response => {
      setPhoneListData(response.data.items)
    })
  }

  const setDefaultValue = () => {
    setPhoneEditId("")
    setTwilioAccountSid("ACXXXXXXXXXXXXXXXXXXXXXXXXXXXX")
    setTwilioAuthToken("")
    setPhoneNumber("+1XXXXXXXXX")
  }

  const deleteSettingPhone = deleteId => {
    if (!window.confirm("Delete this Phone?")) {
      return false
    }

    return new Promise(async () => {
      await AxiosInstance.delete(`settings/phone/` + deleteId).then(response => {
        getPhoneListData()
      })
    })
  }

  useEffect(() => {
    getPhoneOptOut()
    getPhoneListData()
  }, [])

  return (
    <Accordion.Item eventKey="1">
      <Accordion.Header>
        <i className="bi bi-telephone-fill" /> Phone
      </Accordion.Header>
      <Accordion.Body className={"px-3"}>
        <div className="row">
          <div className="col-lg-10 col-md-12 col-xs-12 mx-auto">
            <div className="row g-0">
              <div className="col-lg-6 col-md-10 mx-auto pb-4">
                <div className="input-group mb-3">
                  <span className="input-group-text">Opt-Outs / Block List</span>
                  <select
                    className="form-select"
                    value={phoneOptOut}
                    onChange={e => savePhoneOptOuts(e.target.value)}
                  >
                    <option value="">== SPREADSHEET ==</option>
                    {driveList.length > 0 &&
                      driveList.map(sheetsData => {
                        return (
                          <option key={sheetsData.id} value={sheetsData.id}>
                            {sheetsData.nameDisplay}
                          </option>
                        )
                      })}
                  </select>
                </div>
              </div>
            </div>

            <div className="row g-0 display-none" id="phoneCallSettingsContainer">
              <div className="col-lg-6 col-md-8 mx-auto py-4  px-2">
                <div className="row">
                  <div className="col-lg-12 colHeading">
                    <strong>
                      <i className="bi bi-gear-fill" /> Phone Call Settings
                    </strong>
                  </div>
                  <div className="col-lg-12 dh-block-body">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mb-3 row">
                          <label htmlFor="twilioAccountSid" className="col-sm-4 col-form-label">
                            Click to Call Through:
                          </label>
                          <div className="col-sm-8">
                            <select name="callthrough" id="callthrough" className="form-select">
                              <option value="SwiftPhone">SwiftPhone</option>
                              <option value="GoogleVoice">Google Voice</option>
                              <option value="None">None / Off-App</option>
                            </select>
                          </div>
                        </div>
                        <div className="row">
                          <label className="col-sm-4" />
                          <div className="col-sm-8">
                            <button
                              type="button"
                              name="btnCancel"
                              id="btnCancelPhoneCallSettings"
                              className="btn btn-default"
                            >
                              <i className="bi bi-x-lg" /> Cancel
                            </button>{" "}
                            <button
                              type="button"
                              name="btnSave"
                              id="btnSavePhoneCallSettings"
                              className="btn btn-primary"
                            >
                              <i className="bi bi-check2" /> Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="row g-0 display-none"
              id="addNewPhoneContainer"
              style={{ display: `${showTwilioPhone ? "block" : "none"}` }}
            >
              <div className="col-lg-6 col-md-8 mx-auto py-4  px-2">
                <div className="row">
                  <div className="col-lg-12 colHeading">
                    <strong>
                      <i className="bi bi-telephone-fill" /> Connect Twilio Phone
                    </strong>
                  </div>

                  <div className="col-lg-12 dh-block-body">
                    <div className="row">
                      <div className="col-lg-12">
                        <form id="frmSettingPhone" onSubmit={saveSettingPhone} method="POST">
                          <div className="mb-3 row">
                            <label htmlFor="twilioAccountSid" className="col-sm-4 col-form-label">
                              Twilio Account SID
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                name="twilioAccountSid"
                                id="twilioAccountSid"
                                value={twilioAccountSid}
                                className="form-control"
                                onChange={e => {
                                  setTwilioAccountSid(e.target.value)
                                }}
                              />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label htmlFor="twilioAuthToken" className="col-sm-4 col-form-label">
                              Twilio Auth Token
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                name="twilioAuthToken"
                                id="twilioAuthToken"
                                value={twilioAuthToken}
                                className="form-control"
                                onChange={e => {
                                  setTwilioAuthToken(e.target.value)
                                }}
                              />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label htmlFor="phoneNumber" className="col-sm-4 col-form-label">
                              Phone Number
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                name="phoneNumber"
                                id="phoneNumber"
                                value={phoneNumber}
                                className="form-control"
                                onChange={e => {
                                  setPhoneNumber(e.target.value)
                                }}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <label className="col-sm-4" />
                            <div className="col-sm-8">
                              <button
                                type="button"
                                name="btnCancel"
                                id="btnCancelTwilioPhone"
                                className="btn btn-default"
                                onClick={() => {
                                  setShowTwilioPhone(false)
                                }}
                              >
                                <i className="bi bi-x-lg" /> Cancel
                              </button>{" "}
                              <button
                                type="submit"
                                name="btnSave"
                                id="btnSaveTwilioPhone"
                                className="btn btn-primary"
                              >
                                <i className="bi bi-check2" /> Connect
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-2 align-items-center">
              <div className="col-md-6">
                <h4 className="mb-0">
                  <i className="bi bi-telephone-fill" /> Phone
                </h4>
              </div>
              <div className="col-md-6 text-end">
                <div className="dropdown d-inline-block">
                  <button
                    className="btn btn-primary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="bi bi-plus-lg" /> Add New Phone # <i className="bi bi-telephone-fill" />
                  </button>
                  <ul className="dropdown-menu" style={{}}>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => {
                          setShowPhoneModal(true)
                          setShowTwilioPhone(false)
                          setDefaultValue()
                          setPhoneNumber("")
                        }}
                      >
                        <i className="bi bi-telephone-fill" /> Swift Phone (Provision New)
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item btnAddNewPhone"
                        href="#"
                        onClick={() => {
                          setShowTwilioPhone(true)
                          setShowPhoneModal(false)
                          setDefaultValue()
                        }}
                      >
                        <i className="bi bi-telephone-fill" /> Connect Twilio Phone
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table id="tblPhoneList" className="table table-striped dataTable no-footer" width="100%">
                <thead>
                  <tr>
                    <th width="40%">Phone</th>
                    <th width="40%">Label</th>
                    <th width="20%" />
                  </tr>
                </thead>
                <tbody>
                  {phoneListData.map((phoneValue, phoneKey) => {
                    return (
                      <tr key={phoneKey}>
                        <td>{phoneValue.phoneNumber}</td>
                        <td>{phoneValue.type}</td>
                        <td className="text-end tableColAction d-none d-md-table-cell">
                          <a
                            className="btn btn-edit"
                            onClick={() => {
                              phoneValue.type === "TwilioPhone"
                                ? setShowTwilioPhone(true)
                                : setShowPhoneModal(true)
                              setPhoneEditId(phoneValue.id)
                              setTwilioAccountSid(phoneValue.twilioAccountSid)
                              setTwilioAuthToken(phoneValue.twilioAuthToken)
                              setPhoneNumber(phoneValue.phoneNumber)
                            }}
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip className="ms-1 d-inline-block">Edit</Tooltip>}
                            >
                              <i className="bi bi-pencil-fill" />
                            </OverlayTrigger>
                          </a>{" "}
                          <a
                            className="btn btn-delete"
                            onClick={() => {
                              deleteSettingPhone(phoneValue.id)
                            }}
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip className="ms-1 d-inline-block">Delete</Tooltip>}
                            >
                              <i className="bi bi-trash-fill" />
                            </OverlayTrigger>
                          </a>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>

            <AddSwiftPhoneModal
              showPhoneModal={showPhoneModal}
              setShowPhoneModal={setShowPhoneModal}
              phoneEditId={phoneEditId}
              setPhoneEditId={setPhoneEditId}
              getPhoneListData={getPhoneListData}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
            />
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default Phone
