import * as React from "react"
import { Modal } from "react-bootstrap"
import AxiosInstance from "../../../services/axiosInstance"

const AddSwiftPhoneModal = ({
  showPhoneModal,
  setShowPhoneModal,
  phoneEditId,
  setPhoneEditId,
  getPhoneListData,
  phoneNumber,
  setPhoneNumber,
}) => {
  const saveSettingPhone = e => {
    e.preventDefault()

    const formData = new FormData(e.target)

    if (!formData.get("add_phone_search")) {
      alert("Please enter Phone Number.")
      return false
    }

    const requestData = {
      type: "SwiftPhone",
      phoneNumber: formData.get("add_phone_search"),
    }

    var url = `settings/phone/add`
    if (phoneEditId) {
      url = `settings/phone/edit/${phoneEditId}`
    }

    return new Promise(async () => {
      await AxiosInstance.post(url, requestData)
        .then(response => {
          if (response.status === 201 || response.status === 200) {
            getPhoneListData()
            setPhoneNumber("")
            setPhoneEditId("")
            handleClose()
            return false
          }
        })
        .catch(function (error) {})
    })
  }

  const handleClose = () => {
    setShowPhoneModal(false)
  }

  return (
    <Modal show={showPhoneModal} onHide={handleClose} id="addNewPhoneModal" dialogClassName={`modal-dialog-centered`}>
      <form onSubmit={saveSettingPhone} method="post">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="newPhoneModalTitle">
              <i className="bi bi-telephone-fill" /> Phone Number
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            />
          </div>
          <div className="modal-body">
            <div className="mb-5 row align-items-center">
              <div className="col-sm-12">
                <div className="position-relative">
                  <input
                    className="form-control form-control-lg"
                    type="tel"
                    name="add_phone_search"
                    id="add_phone_search"
                    value={phoneNumber}
                    onChange={e => {
                      setPhoneNumber(e.target.value)
                    }}
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-sm-12 text-center">
                <p>What country &amp; area code?</p>
                <p>
                  <a href="https://portal.SwiftCRM.com/swiftcloud/phoneportrequest" target="_blank">
                    Click here to migrate (port in) an existing phone number.
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-default" data-bs-dismiss="modal" onClick={handleClose}>
              <i className="bi bi-x-lg" /> Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              <i className="bi bi-check-lg" /> Save
            </button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default AddSwiftPhoneModal
